import { ModifierType } from '../schemas/enums';
import { MenuItem, menuItemSchema } from '../schemas/menu/menuItem';
import { Modifier } from '../schemas/menu/modifier';
import { formatCurrency } from '../utils/currency';
import { v4 as uuidv4 } from 'uuid';
import { z } from 'zod';

// Note the use of `z.input<...>` here, not the usual `z.infer<...>` (which is an alias for `z.output<...>`).
// https://stackoverflow.com/questions/72771130/how-to-make-an-optional-property-with-a-default-value-in-zod
type Input = z.input<typeof menuItemSchema>;
type OptionalFields = 'uuid';
type InputWithOptional = Partial<Pick<Input, OptionalFields>> & Omit<Input, OptionalFields>;

export const init = (menuItemInput: InputWithOptional): MenuItem => {
  const menuItem = {
    ...menuItemInput,
    uuid: !menuItemInput.uuid ? uuidv4() : menuItemInput.uuid,
  } satisfies Input;

  return menuItemSchema.parse(menuItem, {
    path: [`Menu item uuid: ${menuItem.uuid}`],
  });
};

export const linkModifierGroup = (
  menuItem: MenuItem,
  modifierGroupUuid: string,
  modifierType: ModifierType
): MenuItem => {
  if (!modifierGroupUuid) {
    throw new Error('modifierGroupUuid must be set!');
  }

  // * OPTION *
  if (modifierType === 'OPTION') {
    const alreadyExists = menuItem.modifierGroupUuids.includes(modifierGroupUuid);
    if (alreadyExists) {
      console.info('Modifier group is already assigned!...skipping save');
      return menuItem;
    }
    return {
      ...menuItem,
      modifierGroupUuids: [...menuItem.modifierGroupUuids, modifierGroupUuid],
    };
  }

  // * SIZE *
  else if (modifierType === 'SIZE') {
    if (menuItem.sizeModifierGroupUuid) {
      console.info('Menu item already has a SIZE group assigned!...skipping save');
      return menuItem;
    }
    return {
      ...menuItem,
      price: null, // Price and size modifier group association are not allowed at the same time.
      sizeModifierGroupUuid: modifierGroupUuid,
    };
  } else {
    return menuItem;
  }
};

export const unlinkModifierGroup = (menuItem: MenuItem, modifierGroupId: string): MenuItem => {
  if (!modifierGroupId) throw new Error(`modifierGroupId must be set!`);

  const isSizeModifierGroup = menuItem.sizeModifierGroupUuid === modifierGroupId;
  return {
    ...menuItem,
    modifierGroupUuids: menuItem.modifierGroupUuids.filter((mgId) => mgId !== modifierGroupId),
    price: isSizeModifierGroup ? 0 : menuItem.price,
    sizeModifierGroupUuid: isSizeModifierGroup ? null : menuItem.sizeModifierGroupUuid,
  };
};

/**
 * Get the formatted price for display, either from the sizeModifier (if exists), or the menu item directly.
 * @param menuItem
 * @param sizeModifiers
 */
export const priceForDisplay = (menuItem: MenuItem, sizeModifiers: Modifier[]) => {
  // Return the price modifier group price if it exists
  if (menuItem.sizeModifierGroupUuid) {
    if (!sizeModifiers) {
      console.warn(`! Expected priceModifiers to be passed into priceForDisplay()`, sizeModifiers);
    }
    // Find the minimum price of the price modifiers and return it formatted as price string.
    if (sizeModifiers.length > 0) {
      const minPrice = Math.min(...sizeModifiers.map((pm) => pm.price));
      return `${formatCurrency(minPrice)}+`;
    }
  }
  // Otherwise return the price of the item
  else {
    if (menuItem.price) return formatCurrency(menuItem.price);
  }
  return formatCurrency(0); // Default to 0.00 if no price is set.
};
